import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { productQueries } from '../../../../graphql/queries';
import Product from './Product.js';
import ProductSkeleton from '../../../../Components/Skeletons/ProductSkeleton';

const VoucherWrapper = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(productQueries.GiftVoucherProductById, {
    variables: { id: parseInt(id) }
  });

  if (!data || loading) {
    return <ProductSkeleton />;
  }

  const product = data.giftVoucherProductById;

  return (<Product product={product} />);
};

export default VoucherWrapper;
