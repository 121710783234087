import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box
} from '@mui/material';
import RemoveButton from '../RemoveButton.js';
import Price from '../Price.js';
import { formatPrice } from '../../../helpers/currency.js';
import {
  getProductPriceWithDiscountByCurrency
} from '../../../helpers/cart.js';
import { useTranslation } from 'react-i18next';

const CartItem = ({ item, onRemoveClick, currency }) => {
  const { t } = useTranslation();

  const handleRemoveClick = () => {
    onRemoveClick(item.id);
  };

  // rather than storing the formatted price in the cart item itself
  // we should just do it on the fly when we need to present the
  // price to the customer as its easier to respond to currency changes
  const formattedPrice = formatPrice(
    getProductPriceWithDiscountByCurrency(currency, item),
    currency
  );

  let discountAmount;

  if (item.discount) {
    discountAmount = item.discountType === 'FixedValue'
      ? formatPrice(item.discount, currency)
      : item.discount + '%';
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='flex-start'
      spacing={3}
    >
      <Grid item md={4}>
        <img src={item.images.cover} alt={item.title} width={112} height={112} />
      </Grid>
      <Grid item md={8}>
        <Typography variant='h4'>
          {item.title}
        </Typography>
        {item.author && (
          <Typography variant='body1' sx={{ fontSize: 14 }}>
            by {item.author}
          </Typography>
        )}
        {
          item.gift &&
            <Typography variant='body3'>
              ({t('gift-to-person', { person: item.gift.toEmail })})
            </Typography>
        }
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '3rem' }}>
          <span>
            {
              onRemoveClick
                ? (
                    <RemoveButton
                      onClick={handleRemoveClick}
                    />
                  )
                : null
            }
          </span>
          <Price variant='h4' price={formattedPrice} />
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {
            discountAmount && (
              <Typography variant='body3'>
                {t('inc-amount-discount', { amount: discountAmount })}
              </Typography>
            )
          }
        </Box>
      </Grid>
    </Grid>
  );
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func
};

export default CartItem;
