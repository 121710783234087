import _ from 'lodash';
import { formatURLTitle } from './http.js';
import { getEnvVar } from './env.js';
import { convertGraphQLLanguageName } from './locale.js';

const getIndex = (id, items) => {
  const index = _.findIndex(items, (item) => {
    return item.id === id;
  });
  return index;
};

const isFinalSetInCollection = (setId, sets) => {
  const indexOfCurrentSet = getIndex(setId, sets);
  return indexOfCurrentSet === (sets.length - 1);
};

const isFinalHandInSet = (handId, hands) => {
  const indexOfCurrentHand = getIndex(handId, hands);
  return indexOfCurrentHand === (hands.length - 1);
};

const isFinalHandInProduct = (setId, sets, handId, hands) => {
  return isFinalSetInCollection(setId, sets) && isFinalHandInSet(handId, hands);
};

const getNextSetInCollection = (setId, sets) => {
  const indexOfCurrentSet = getIndex(setId, sets);
  return sets[indexOfCurrentSet + 1];
};

const getNextHandInSet = (handId, hands) => {
  const indexOfCurrentHand = getIndex(handId, hands);
  return hands[indexOfCurrentHand + 1];
};

const convertCollectionSetsToSets = (collectionSets) => {
  if (collectionSets) {
    const sets = [];
    collectionSets.forEach(collectionSet => {
      sets.push(collectionSet.product);
    });
    return sets;
  }
  return null;
};

const formatPathToHandPlay = (collectionId, set, hand) => {
  let pathToHandPlay = `/${convertGraphQLLanguageName(set.language)}`;
  if (collectionId) {
    pathToHandPlay += `/collections/${collectionId}`;
  }
  pathToHandPlay += `/sets/${set.id}/${formatURLTitle(set.title)}/hands/${hand.id}/${formatURLTitle(hand.title)}`;
  return pathToHandPlay;
};

const formatProductDataForTree = (data) => {
  const getHands = (handsData) => {
    return handsData.map(hand => {
      return {
        id: hand.id,
        title: hand.title,
        type: 'hand'
      };
    });
  };

  const formattedData = {
    title: `Search returned ${data.length} products`,
    type: 'root',
    id: '0',
    children: []
  };

  // data is an array
  data.forEach((product) => {
    const formattedProduct = {
      id: product.id,
      title: product.title,
      type: product.type.toLowerCase()
    };
    if (product.type === 'Collection') {
      formattedProduct.children = [];
      product.collection.collectionSets.forEach((cs) => {
        formattedProduct.children.push({
          id: cs.product.id,
          title: cs.product.title,
          type: 'set',
          children: getHands(cs.product.hands)
        });
      });
    } else {
      formattedProduct.children = getHands(product.hands);
    }
    formattedData.children.push(formattedProduct);
  });

  return formattedData;
};

// all product grid / list items in the store/library should use this url
const buildInternalProductUrl = (product) => {
  let url;

  if (product.type === 'GiftVoucher') {
    url = `/products/vouchers/${product.id}/${formatURLTitle(product.title)}`;
  } else if (product.language) {
    url = `/products/${convertGraphQLLanguageName(product.language)}/${product.id}/${formatURLTitle(product.title)}`;
  } else {
    url = `/products/${product.id}/${formatURLTitle(product.title)}`;
  }
  return url;
};

const buildProductLink = (language, linkId, internal) => {
  let siteUrl = '';
  if (!internal) {
    siteUrl = getEnvVar('SITE_URL');
  }
  // /:language/products/:linkId
  return `${siteUrl}/${convertGraphQLLanguageName(language)}/products/${linkId}`;
};

const buildProductLinks = (language, linkIds) => {
  return linkIds.map(linkId => {
    return {
      link: buildProductLink(language, linkId.linkId)
    };
  });
};

const buildProductLinkForHand = (language, linkId, hand, setId, setTitle, internal) => {
  let siteUrl = '';
  if (!internal) {
    siteUrl = getEnvVar('SITE_URL');
  }
  // /:language?/:linkId?/sets/:setId/:setTitle/hands/:handId/:handTitle
  let link = `${siteUrl}/${convertGraphQLLanguageName(language)}/products/${linkId}/${setId}/${formatURLTitle(setTitle)}/hands/${hand.id}/${formatURLTitle(hand.title)}`;
  if (!internal) {
    link += '/embed';
  }
  return link;
};

const buildProductLinksForHand = (language, linkIds, hand, setId, setTitle) => {
  return linkIds.map(linkId => {
    return {
      link: buildProductLinkForHand(language, linkId.linkId, hand, setId, setTitle)
    };
  });
};

const generateIframeForLinkedProduct = (src, width, height) => {
  const iframeMarkup = '<iframe ' +
                          `src="${src}" ` +
                          `width="${width}" ` +
                          `height="${height}" ` +
                          'border="0" ' +
                          'frameborder="0" ' +
                          'scrolling="no"' +
                          'style="overflow-x: hidden; overflow-y: scroll"' +
                        '></iframe>';
  return iframeMarkup;
};

export {
  isFinalSetInCollection,
  isFinalHandInSet,
  isFinalHandInProduct,
  getNextSetInCollection,
  getNextHandInSet,
  convertCollectionSetsToSets,
  formatPathToHandPlay,
  formatProductDataForTree,
  buildInternalProductUrl,
  buildProductLink,
  buildProductLinks,
  buildProductLinkForHand,
  buildProductLinksForHand,
  generateIframeForLinkedProduct
};
