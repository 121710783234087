import React, { useState, useContext } from 'react';
import {
  getCart,
  persistCart,
  addToCart as addToCartHelper,
  removeFromCart as removeFromCartHelper,
  getTotalBillableAmount,
  checkIfFreeAfterDiscount as checkIfFreeAfterDiscountHelper,
  giftItemsOnly,
  getProductPriceByCurrency
} from '../helpers/cart.js';
import { LocaleContext } from './LocaleContext.js';

const CartContext = React.createContext();

// cart = {
//   items: [
//     {
//       id,
//       title,
//       image,
//       price,
//       discount,
//       gift: {
//         toFirstName,
//         toLastName,
//         toEmail,
//         message
//       }
//     }
//   ],
//   voucher
// }

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(getCart());

  const { locale } = useContext(LocaleContext);

  if (!locale) {
    return null;
  }

  const getTotal = () => {
    if (cart && cart.items && cart.items.length > 0) {
      return getTotalBillableAmount(locale.currency, cart.items);
    }
    return '';
  };

  const checkIfInCart = (item) => {
    if (cart && cart.items) {
      return !!cart.items.find(cartItem => {
        return cartItem.id === item.id && !cartItem.gift;
      });
    }

    return false;
  };

  const checkIfFreeAfterDiscount = () => {
    if (cart && cart.items && cart.items.length > 0) {
      return checkIfFreeAfterDiscountHelper(locale.currency, cart.items);
    }

    return false;
  };

  const addToCart = (product, gift) => {
    const newItem = {
      id: product.id,
      title: product.title,
      author: product.author ? product.author.fullName : null,
      images: {
        cover: product.images.cover
      },
      price: product.price,
      discount: 0,
      gift: gift && {
        toFirstName: gift.toFirstName,
        toLastName: gift.toLastName,
        toEmail: gift.toEmail,
        message: gift.message
      }
    };

    setCart(cart => {
      const newCartItems = addToCartHelper(
        cart && cart.items ? [...cart.items] : [],
        newItem
      );
      const newCart = { ...cart, items: newCartItems };
      newCart.giftsOnly = giftItemsOnly(newCart.items);

      persistCart(newCart);
      return newCart;
    });

    // if a voucher was applied previously,
    // reapply it so it can affect the new item too
    if (cart && cart.voucher) {
      applyDiscount(cart.voucher);
    }
  };

  const removeFromCart = (id) => {
    // remove product from cart
    setCart(cart => {
      const newCartItems = removeFromCartHelper(
        [...cart.items],
        id
      );
      let newCart = { ...cart, items: newCartItems };
      if (newCart.items.length === 0) {
        newCart = null;
      } else {
        newCart.giftsOnly = giftItemsOnly(newCart.items);
      }
      persistCart(newCart);
      return newCart;
    });
  };

  const clearCart = () => {
    setCart(null);
    persistCart(null);
  };

  const applyDiscount = (voucher) => {
    const { value, balance, product, discountType } = voucher;

    // if the voucher has a product id,
    // it can only be applied to the specified product
    let voucherCanBeApplied = false;

    if (!product || cart.items.find(item => item.id === product.id)) {
      voucherCanBeApplied = true;
    }

    let fixedValueLeft = balance;

    if (voucherCanBeApplied) {
      setCart(cart => {
        const discountedCartItems = [...cart.items];

        discountedCartItems.forEach((item) => {
          item.discountType = discountType;

          if (discountType === 'Percentage') {
            if (!product || item.id === product.id) {
              item.discount = value;
            }
          } else if (discountType === 'FixedValue' && fixedValueLeft > 0) {
            const productCost = getProductPriceByCurrency(locale.currency, item.price);

            item.discount = Math.min(productCost, fixedValueLeft);
            fixedValueLeft -= item.discount;
          }
        });

        const newCart = { ...cart, voucher, items: discountedCartItems };

        persistCart(newCart);
        return newCart;
      });
    }

    return voucherCanBeApplied;
  };

  const removeDiscount = () => {
    setCart(cart => {
      const cartItems = [...cart.items];

      cartItems.forEach((cartItem, i) => {
        cartItems[i] = {
          ...cartItem,
          discount: null,
          discountType: null
        };
      });

      const newCart = {
        ...cart,
        voucher: null,
        items: cartItems
      };

      persistCart(newCart);
      return newCart;
    });
  };

  // console.log('++++++++++++++ CART', cart);

  return (
    <CartContext.Provider value={{
      cart,
      getTotal,
      addToCart,
      removeFromCart,
      checkIfInCart,
      checkIfFreeAfterDiscount,
      clearCart,
      applyDiscount,
      removeDiscount,
      currency: locale.currency
    }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
