import React, { useContext, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../graphql';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import PurchasesByUserId from '../../../Components/Queries/PurchasesByUserId.js';
import { getPriceAfterDiscount } from '../../../helpers/cart.js';
import { formatPrice } from '../../../helpers/currency';
import { buildInternalProductUrl } from '../../../helpers/product';
import { UserContext } from '../../../Context';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  thead: {
    '@media all and (max-width: 600px)': {
      border: 'none',
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: 1
    }
  },
  tr: {
    '@media all and (max-width: 600px)': {
      borderBottom: '3px solid #ddd',
      display: 'block',
      '& td': {
        borderBottom: '1px solid #ddd',
        display: 'block',
        textAlign: 'right',
        width: '100%'
      },
      '& > td:nth-child(4)': {
        borderBottom: '0px solid #ddd',
        display: 'block'
      },
      '& td::before': {
        content: 'attr(datalabel)',
        float: 'left'
      }
    }
  },
  itemTable: {
    '& tr': {
      '& td': {
        border: 'none'
      }
    }
  }
}));

const AdminPurchases = ({ user }) => {
  return <Purchases user={user} />;
};

const AccountPurchases = () => {
  const user = useContext(UserContext).user;
  return <Purchases user={user} />;
};

const Purchases = ({ user }) => {
  const outletContext = useOutletContext();
  const [resendId, setResendId] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  let handleSubmitSuccess, handleSubmitError;
  if (outletContext) {
    ({ handleSubmitSuccess, handleSubmitError } = outletContext);
  }
  const classes = useStyles();
  const { t } = useTranslation();

  const [resendGiftEmail] = useMutation(mutations.ResendGiftEmail, {
    onCompleted: () => {
      handleSubmitSuccess(t('gift-email-successfully-sent'));
    },
    onError: (err) => {
      handleSubmitError(err);
    }
  });

  const handleSendEmailClick = purchaseItemId => async () => {
    setResendLoading(true);
    setResendId(purchaseItemId);
    await resendGiftEmail({
      variables: {
        purchaseItemId
      }
    });
    setResendLoading(false);
  };

  return (
    <PurchasesByUserId userId={user.id}>
      {purchasesByUserId => (
        <Table align='left' sx={{ float: 'none' }}>
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('product-items')}</TableCell>
              <TableCell>{t('total-price')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              purchasesByUserId.purchases && purchasesByUserId.purchases.map(purchase => {
                if (purchase.status === 'Succeeded') {
                  return (
                    <TableRow key={purchase.id} className={classes.tr}>
                      <TableCell dataLabel={t('date')}>
                        {moment(purchase.purchaseDate, 'MMMM Do YYYY').format('YYYY-MM-DD')}
                      </TableCell>
                      <TableCell>
                        <Table className={classes.itemTable}>
                          {
                            purchase.purchaseItems.map((pi, index) => {
                              const discountedPrice = getPriceAfterDiscount(pi.price, pi.discount, pi.discountType);
                              let discountAmount;

                              if (pi.discount && pi.discount > 0) {
                                discountAmount = pi.discountType === 'FixedValue'
                                  ? formatPrice(pi.discount, purchase.currency)
                                  : `${pi.discount}%`;
                              }

                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Link to={buildInternalProductUrl(pi.product)}>
                                      {pi.product.title}
                                    </Link>
                                    {
                                        pi.giftedToUserId &&
                                          <p>[{t('sent-as-a-gift')}]</p>
                                      }
                                  </TableCell>
                                  <TableCell sx={{ width: '10%' }}>
                                    {formatPrice(discountedPrice, purchase.currency)}<br />
                                    {
                                      discountAmount && (
                                        <Typography variant='caption'>({t('inc-amount-discount', { amount: discountAmount })})</Typography>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell sx={{ width: '25%' }}>
                                    {
                                      pi.giftedToUserId
                                        ? (
                                          <StoreButton
                                            color='secondary'
                                            loading={resendLoading && resendId === pi.id}
                                            variant='outlined'
                                            onClick={handleSendEmailClick(pi.id)}
                                            label='resend-email'
                                          />
                                          )
                                        : null
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          }
                        </Table>
                      </TableCell>
                      <TableCell dataLabel={t('total-price')}>
                        {purchase.totalPriceFormatted}<br />
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })
            }
          </TableBody>
        </Table>
      )}
    </PurchasesByUserId>
  );
};

export {
  AdminPurchases,
  AccountPurchases
};
