import React from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

const RemoveButton = (props) => {
  const { t } = useTranslation();

  return (
    <Button
      variant='text'
      startIcon={<DeleteIcon />}
      sx={{borderRadius: 2}}
      {...props}
    >
      {t('remove')}
    </Button>
  );
};

export default RemoveButton;
