import React from 'react';
import { voucherQueries } from '../../../graphql/queries';
import AdminPage from '../../../Components/AdminPage.js';
import VoucherDetails from './VoucherDetails.js';
import AddVoucher from './AddVoucher.js';

const Vouchers = () => {
  const columns = [
    { id: 'code', label: 'Code', sortColumn: 'code' },
    { id: 'value', label: 'Discount %', sortColumn: 'value' },
    { id: 'dateCreated', label: 'Created', sortColumn: 'createdAt' }
  ];
  return (
    <>
      <AdminPage
        columns={columns}
        query={voucherQueries.VoucherSearch}
        initialOrderBy='id'
        rowsDataPath='voucherSearch.vouchers'
        countDataPath='voucherSearch.count'
        showAddButton
        sectionName='vouchers'
      >
        {
          (id, onSubmitSuccess, onCancelClick, refetch, openSnackbar) => (
            <>
              {
              id && id > 0
                ? <VoucherDetails
                    id={id}
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
                : <AddVoucher
                    onCancelClick={onCancelClick}
                    onSubmitSuccess={(message) => {
                      openSnackbar(message, 'success');
                      onSubmitSuccess(refetch);
                    }}
                    onSubmitError={(message) => {
                      openSnackbar(message, 'error');
                    }}
                  />
            }
            </>
          )
        }
      </AdminPage>
    </>
  );
};

export default Vouchers;
