import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { DrawerContext, LocaleContext } from '../../../../Context';
import Checkout from './Checkout.js';
import { useCheckout } from '../../../../Hooks';

// this component is just an interface between the useCheckout hook
// and the Checkout.js UI
// this enables us to:
// 1. seperate UI and state management
// 2. seperate UI and data management
// 3. use storybook to easily test the UI
// 4
const CheckoutWrapper = ({ stripe, elements }) => {
  const { openDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();

  const { locale } = useContext(LocaleContext);

  const {
    setSelectedPaymentMethod,
    setSavePaymentMethod,
    setRegisterFields,
    setRegisterFormComplete,
    setCardFormComplete,
    setSelectedBank,
    isFreeAfterDiscount,
    redeemVoucher,
    removeVoucher,
    submitPayment,
    clearCart,
    checkoutState,
    user,
    isValid,
    cart,
    total
  } = useCheckout(stripe, elements, locale);

  const handleSuccessComplete = useCallback(async () => {
    // check if cart has already been cleared
    if (cart) {
      const giftsOnly = cart && cart.giftsOnly;
      clearCart();
      if (giftsOnly) {
        navigate('/products');
      } else {
        navigate('/library');
      }
    }
  }, [clearCart, cart, navigate]);

  if (!checkoutState) {
    return null;
  }

  const {
    submitLoading,
    redeemLoading,
    error,
    paymentMethods,
    selectedPaymentMethodId,
    savePaymentMethod,
    selectedBank,
    remainingBalance,
    success
  } = checkoutState;

  const handleBackClick = () => {
    // this is to stop us going bacl to page outside of the site...
    // i.e. if you goto the checkout page after visiting another website
    // https://stackoverflow.com/a/71647428
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      // the current entry in the history stack will be replaced with the new one with { replace: true }
      navigate('/', { replace: true });
    }
  };

  const handleLoginClick = () => {
    openDrawer('login');
  };

  const handleSavePaymentMethodChange = (event) => {
    setSavePaymentMethod(event.target.checked);
  };

  const handleBankSelected = (event) => {
    setSelectedBank(event.target.value);
  };

  const handleRegisterFormComplete = (isComplete, fields) => {
    setRegisterFormComplete(isComplete);
    if (isComplete) {
      setRegisterFields(fields);
    }
  };

  if (!locale) {
    return null;
  }

  return (
    <Checkout
      isValid={isValid}
      submitLoading={submitLoading}
      redeemLoading={redeemLoading}
      success={success}
      error={error}
      cart={cart}
      total={total}
      loggedIn={false}
      user={user}
      locale={locale}
      isFreeAfterDiscount={isFreeAfterDiscount}
      paymentMethods={paymentMethods}
      selectedPaymentMethodId={selectedPaymentMethodId}
      savePaymentMethod={savePaymentMethod}
      selectedBank={selectedBank}
      remainingBalance={remainingBalance}
      successText={cart && cart.giftsOnly ? 'your-gift-purchase-is-complete' : 'your-purchase-is-complete'}
      onBackClick={handleBackClick}
      onLoginClick={handleLoginClick}
      onVoucherApplyClick={redeemVoucher}
      onRemoveVoucherClick={removeVoucher}
      onSubmitClick={submitPayment}
      onPaymentMethodChange={setSelectedPaymentMethod}
      onSavePaymentMethodChange={handleSavePaymentMethodChange}
      onRegisterFormComplete={handleRegisterFormComplete}
      onCardFormComplete={setCardFormComplete}
      onBankSelected={handleBankSelected}
      onSuccessComplete={handleSuccessComplete}
    />
  );
};

CheckoutWrapper.propTypes = {
  stripe: PropTypes.object,
  elements: PropTypes.object
};

export default injectStripe(CheckoutWrapper);
