import gql from 'graphql-tag';

const royaltyQueries = {
  RoyaltySearch: (options) => {
    return gql`
      query {
        royaltySearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
            dateFrom: "${options.dateFrom}"
            dateTo: "${options.dateTo}"
          },
          currency: ${options.currency}
         ) {
          count
          royalties {
            authorId
            authorFullName
            royaltyPercentage
            totalSales
            totalRoyalty
            authorPurchaseItems {
              price
              discount
              discountType
            }
          }
        }
      }
    `;
  },
  RoyaltyDetails: (options) => {
    return gql`
      query {
        royaltyDetails (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
            dateFrom: "${options.dateFrom}"
            dateTo: "${options.dateTo}"
          },
          authorId: ${options.authorId},
          currency: ${options.currency}
         ) {
          count
          purchaseItems {
            discount
            discountType
            price
            purchase {
              currency
            }
            product {
              title
              price {
                priceUSD
                priceGBP
                priceEUR
                priceCAD
                priceAUD
                priceNZD
              }
            }
          }
        }
      }
    `;
  }
};

export default royaltyQueries;
