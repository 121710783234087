import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tags from '../../../../Components/Store/Tags.js';
import Price from '../../../../Components/Store/Price.js';
import SectionAccordian from './Components/SectionAccordian.js';
import SectionHands from './Components/SectionHands.js';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';
import BBPaper from '../../../../Components/Store/BBPaper.js';
import Author from '../../../../Components/Store/AuthorLink.js';
import { CartContext, DrawerContext, GiftContext, UserContext } from '../../../../Context';
import { hasPurchasedProduct } from '../../../../helpers/purchase.js';

const useStyles = makeStyles((theme) => ({
  productDetails: {
    marginBottom: '8rem',
    marginTop: '-1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4rem'
    }
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  title: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 0
    }
  },
  tags: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  description: {
    marginBottom: '2rem',
    display: 'block'
  },
  priceAndBuyButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    padding: '3rem 0',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '2rem 0'
    }
  },
  buyButtons: {
    display: 'flex',
    gap: '1.5rem'
  },
  setsAndHands: {
    [theme.breakpoints.down('md')]: {
      padding: '4rem 2rem'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: '-1.5rem',
      width: 'calc(100% + 3rem)'
    }
  }
}));

const Product = ({ product, linkId }) => {
  const { addToCart, removeFromCart, checkIfInCart } = useContext(CartContext);
  const { openDrawer } = useContext(DrawerContext);
  const { initGift } = useContext(GiftContext);
  const { user } = useContext(UserContext);
  const classes = useStyles();

  const purchased = linkId || (
    user &&
    hasPurchasedProduct(product.id, user.products) &&
    product.type !== 'GiftVoucher');

  const handleRemoveFromCartClick = () => {
    removeFromCart(product.id);
    openDrawer('cart');
  };

  const handleCartClick = () => {
    addToCart(product);
    openDrawer('cart');
  };

  const handleGiftClick = () => {
    initGift(product);
    openDrawer('gift');
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={{ xs: 1, sm: 3 }}
        className={classes.productDetails}
      >
        <Grid item sm={5} className={classes.image}>
          <img width='100%' src={product.images.cover} alt={product.title} />
        </Grid>
        <Grid item sm={7}>
          <div className={classes.tags}>
            <Tags tags={product.tagsCache} />
          </div>
          <Typography variant='h1' className={classes.title}>
            {product.title}
          </Typography>
          <div className={classes.priceAndBuyButtons}>
            <Price product={{ ...product, purchased }} variant='h1' />
            <div className={classes.buyButtons}>
              {
                !purchased && (
                  checkIfInCart(product)
                    ? (<StoreButton label='remove-from-cart' onClick={handleRemoveFromCartClick} color='warning' />)
                    : (<StoreButton label='add-to-cart' onClick={handleCartClick} />)
                )
              }
              <StoreButton label='send-as-gift' onClick={handleGiftClick} color='secondary' />
            </div>
          </div>
          <Typography variant='body4' className={classes.description}>
            <Box
              dangerouslySetInnerHTML={
                { __html: product.description }
              }
            />
          </Typography>
          {product.author && (
            <Author author={product.author} />
          )}
        </Grid>
      </Grid>

      {(product.sets || product.hands) && (
        <BBPaper className={classes.setsAndHands}>
          {
            product.sets && product.sets.length > 0
              ? <Typography variant='h2'>{product.sets.length} Sections - what you'll learn</Typography>
              : null
          }
          {
            product.sets && product.sets.length > 0
              ? (
                <SectionAccordian
                  product={product}
                  purchased={purchased}
                  linkId={linkId}
                />
                )
              : (
                <SectionHands
                  productId={product.id}
                  set={product}
                  linkId={linkId}
                  purchased={purchased}
                />
                )
          }
        </BBPaper>
      )}
    </>
  );
};

export default Product;
