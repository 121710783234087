import gql from 'graphql-tag';

const purchaseFragments = {
  fullPurchase: gql`
    fragment FullPurchase on Purchase {
      id
      stripeId
      currency
      totalPrice
      totalPriceFormatted
      purchaseDate
      refundDate
      status
      failureMessage
      paymentType
      purchaseItems {
        id
        price
        discount
        discountType
        giftedToUserId
        gift {
          message
        }
        product {
          id
          type
          title
          images
          author {
            id
            fullName
          }
        }
      }
      user {
        id
        fullName
        language
        currency
        stripeCustomerId
        stripePaymentMethodId
      }
      voucher {
        id
        code
      }
    }
  `
};

export default purchaseFragments;
