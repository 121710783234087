import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormControl
} from '@mui/material';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import StoreInput from '../../../../../Components/Forms/Store/StoreInput.js';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(1)
  },
  submit: {
    marginTop: '2.4rem'
  },
  formItem: {
    paddingRight: '1rem',
    '@media all and (max-width: 480px)': {
      paddingRight: '.5rem'
    }
  }
}));

const RedeemVoucher = ({ onVoucherApplyClick, isLoading }) => {
  const classes = useStyles();
  const [code, setCode] = useState('');

  const handleInputChange = ({ value }) => {
    setCode(value.trim().toUpperCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onVoucherApplyClick(code);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6} className={classes.formItem}>
          <FormControl variant='standard' className={classes.margin}>
            <StoreInput
              id='code'
              label='promo-code'
              maxLength={50}
              value={code}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant='standard' className={classes.submit}>
            <StoreButton
              type='submit'
              label='apply-promo'
              loading={isLoading}
              disabled={isLoading}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

RedeemVoucher.propTypes = {
  onVoucherApplyClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default RedeemVoucher;
