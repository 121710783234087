import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';

const PurchaseItems = ({ items }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Author</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Discount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          items.map(item => (
            <TableRow key={item.id}>
              <TableCell>
                <a href={`/admin/products/${item.product.type}/${item.product.id}/${item.product.title}`}>
                  {item.product.title}
                </a>
              </TableCell>
              <TableCell>
                {
                  item.product.author
                    ? (
                      <>{item.product.author.fullName}</>
                      )
                    : (
                      <>N/A</>
                      )
                }
              </TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{item.discount}%</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

export default PurchaseItems;
