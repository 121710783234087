import React from 'react';
import GridView from '../../../../Components/Store/GridView.js';
import { useQuery } from '@apollo/client';
import { productQueries } from '../../../../graphql/queries';
import GridViewSkeleton from '../../../../Components/Skeletons/GridViewSkeleton.js';

const GiftVouchers = () => {
  const { data, loading } = useQuery(productQueries.GiftVoucherProducts);

  if (loading || !data) {
    return (<GridViewSkeleton nowrap />);
  }

  return (
    <GridView
      products={data.giftVoucherProducts}
    />
  );
};

export default GiftVouchers;
